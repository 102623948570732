import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "foundations-of-type-design",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#foundations-of-type-design",
        "aria-label": "foundations of type design permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Foundations of Type Design`}</h1>
    <p>{`In this all-comprehensive course, students will learn about the foundations of type design, how to get started on designing their first typeface, and how to plan out a series of styles.`}</p>
    <p><img parentName="p" {...{
        "src": "https://raw.githubusercontent.com/lynneyun/foundations-of-type-website/master/static/type_a-z.gif?token=AJJPOR33M2P5CS3O3LWM3OTBO5VQ6",
        "alt": "Course Hero Image"
      }}></img></p>
    <h3 {...{
      "id": "the-course-in-a-nutshell",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#the-course-in-a-nutshell",
        "aria-label": "the course in a nutshell permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`The Course In a Nutshell`}</h3>
    <ul>
      <li parentName="ul">{`Build a solid foundation of theory & history`}</li>
      <li parentName="ul">{`Create ideas for typeface design`}</li>
      <li parentName="ul">{`Learn the practicalities of system of letters`}</li>
      <li parentName="ul">{`Know what is under the hood of font software`}</li>
      <li parentName="ul">{`Envision how your design can expand`}</li>
      <li parentName="ul">{`Learn the inspiring history of the Latin Alphabet`}</li>
      <li parentName="ul">{`Watch with English & Spanish subtitles`}</li>
    </ul>
    <h3 {...{
      "id": "your-support-matters-️",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#your-support-matters-%EF%B8%8F",
        "aria-label": "your support matters ️ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Your Support Matters ❤️`}</h3>
    <p>{`This course is accessible to everyone — and thus, has no paywall. This means that I rely on your support to maintain the site and create more projects. ☕️ `}</p>
    <p><strong parentName="p">{`Please `}<a parentName="strong" {...{
          "href": "https://www.paypal.com/donate?hosted_button_id=EJMNLBTAH6WUA"
        }}>{`donate via this link`}</a></strong>{`  — whether it be $5 or $50, any amount is greatly appreciated. (Or you can `}<a parentName="p" {...{
        "href": "https://www.futurefonts.xyz/space-type/"
      }}>{`buy my typeface`}</a>{`) ❤️`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      